import { LocalStorageKeyType } from '@/constants';

export const setLocalStorage = (key: LocalStorageKeyType, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (
  key: LocalStorageKeyType
): { [key: string]: number | string | boolean } | null => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
};

export const removeLocalStorage = (key: LocalStorageKeyType) => {
  localStorage.removeItem(key);
};

export const clearLocalStorage = () => {
  localStorage.clear();
};
