import { SportType } from '@/generated/schema';

export const getPlaygroundTypeIcon = (type: SportType | string): string => {
  switch (type) {
    case SportType.Archery:
      return '🏹';
    case SportType.Badminton:
      return '🏸';
    case SportType.Basketball:
      return '🏀';
    case SportType.Billiard:
      return '🎱';
    case SportType.Bowling:
      return '🎳';
    case SportType.Boxing:
      return '🥊';
    case SportType.Camping:
      return '⛺';
    case SportType.Canoeing:
      return '🛶';
    case SportType.Climbing:
      return '🧗';
    case SportType.Cycling:
      return '🚴';
    case SportType.Dance:
      return '💃';
    case SportType.Fishing:
      return '🎣';
    case SportType.Fitness:
      return '🧚‍♂️';
    case SportType.Golf:
      return '⛳';
    case SportType.Gym:
      return '🏋️';
    case SportType.Hiking:
      return '🥾';
    case SportType.MartialArt:
      return '🥋';
    case SportType.Pickleball:
      return '🏓';
    case SportType.Running:
      return '🏃';
    case SportType.Shooting:
      return '🔫';
    case SportType.Skating:
      return '🛹';
    case SportType.Soccer:
      return '⚽';
    case SportType.Swimming:
      return '🏊';
    case SportType.TableTennis:
      return '🏓';
    case SportType.Tennis:
      return '🎾';
    case SportType.Volleyball:
      return '🏐';
    case SportType.Walking:
      return '🚶';
    case SportType.Yoga:
      return '🧘';
    default:
      return '';
  }
};
